import gsap from 'gsap';

const themeMintygreen = (container) => {

    const tl = gsap.timeline();

    let main = container.querySelector('.main__content')

    tl
        .to(main, { backgroundColor: '#f4f2d6', autoAlpha: 1}, 0)
        .to(container, { pointerEvents: 'all', userSelect: 'auto'})

    return tl;


}

export default themeMintygreen;
